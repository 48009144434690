<template>
  <div class="view-box">
    <div class="view-box-title">
      <!-- <div style="float: right">
         <a-button type="primary" ghost shape="round">当日</a-button>
         <a-button style="margin-left: 10px" type="primary" ghost shape="round">当月</a-button>
       </div>-->
    </div>
    <div class="view-box-content" style=" border: 0px solid #4e7bce;">
      <a-row>
        <a-col :span="12">
          <PieChart2 :data="pie1Data" :classifyDate="classifyDate1" id="pie1"></PieChart2>
          <div style="width:100%;text-align: center">任务状态统计</div>
        </a-col>
        <a-col :span="12">
          <PieChart2 :data="pie2Data" :classifyDate="classifyDate2" id="pie2"></PieChart2>
          <div style="width:100%;text-align: center">上报类型统计</div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
  import PieChart2 from './PieChart'
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'CenterTwo',
    components: { PieChart2 },
    data () {
      return {
        classifyDate1: '任务数',
        classifyDate2: '上报总数',
        color: ['#FFCC99', '#CC6633'],
        data: [
          { type: '动力设备', value: 20 },
          { type: '一体化提升泵', value: 18 },
          { type: '电器设备', value: 32 },
          { type: '膜设备', value: 46 },
          { type: '检测设备', value: 46 }
        ],
        data1: [
          { type: '库存设备', value: 64 },
          { type: '在线设备', value: 98 }
        ],
        data2: [
          { type: '库存良好', value: 50 },
          { type: '库存损坏', value: 14 }
        ],
        pie1Data: [],
        pie2Data: []
      }
    },
    created () {
      this.getReportStatistics()
    },
    methods: {
      getReportStatistics () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.dispatchDatavApi.getReportStatistics,
          noTips: true,
          loading: 'no',
          success: (data) => {
            this.pie1Data = data.body.bindStatusData
            this.pie2Data = data.body.typeData
          },
          error: () => {
          }
        })
      }
    }
  }
</script>